import 'Modules/@apostrophecms/ui/scss/global/import-all.scss';
import emitter from 'tiny-emitter/instance';
window.apos.bus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
};import AccountBoxIcon from '@apostrophecms/vue-material-design-icons/AccountBox.vue';
import AlertCircleIcon from '@apostrophecms/vue-material-design-icons/AlertCircle.vue';
import AlphaXIcon from '@apostrophecms/vue-material-design-icons/AlphaX.vue';
import AnchorIcon from '@apostrophecms/vue-material-design-icons/Anchor.vue';
import AppleKeyboardCapsIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardCaps.vue';
import AppleKeyboardCommandIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardCommand.vue';
import AppleKeyboardControlIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardControl.vue';
import AppleKeyboardOptionIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardOption.vue';
import AppleKeyboardShiftIcon from '@apostrophecms/vue-material-design-icons/AppleKeyboardShift.vue';
import ArchiveArrowDownIcon from '@apostrophecms/vue-material-design-icons/ArchiveArrowDown.vue';
import ArchiveArrowUpIcon from '@apostrophecms/vue-material-design-icons/ArchiveArrowUp.vue';
import ArrowDownIcon from '@apostrophecms/vue-material-design-icons/ArrowDown.vue';
import ArrowLeftIcon from '@apostrophecms/vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from '@apostrophecms/vue-material-design-icons/ArrowRight.vue';
import ArrowUpIcon from '@apostrophecms/vue-material-design-icons/ArrowUp.vue';
import BinocularsIcon from '@apostrophecms/vue-material-design-icons/Binoculars.vue';
import CalendarIcon from '@apostrophecms/vue-material-design-icons/Calendar.vue';
import CheckAllIcon from '@apostrophecms/vue-material-design-icons/CheckAll.vue';
import CheckBoldIcon from '@apostrophecms/vue-material-design-icons/CheckBold.vue';
import CheckCircleIcon from '@apostrophecms/vue-material-design-icons/CheckCircle.vue';
import CheckDecagramIcon from '@apostrophecms/vue-material-design-icons/CheckDecagram.vue';
import CheckboxBlankOutlineIcon from '@apostrophecms/vue-material-design-icons/CheckboxBlankOutline.vue';
import CheckboxMarkedIcon from '@apostrophecms/vue-material-design-icons/CheckboxMarked.vue';
import ChevronDownIcon from '@apostrophecms/vue-material-design-icons/ChevronDown.vue';
import ChevronLeftIcon from '@apostrophecms/vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from '@apostrophecms/vue-material-design-icons/ChevronRight.vue';
import ChevronUpIcon from '@apostrophecms/vue-material-design-icons/ChevronUp.vue';
import CircleIcon from '@apostrophecms/vue-material-design-icons/Circle.vue';
import CircleMediumIcon from '@apostrophecms/vue-material-design-icons/CircleMedium.vue';
import ClipboardPlusIcon from '@apostrophecms/vue-material-design-icons/ClipboardPlus.vue';
import ClipboardPlusOutlineIcon from '@apostrophecms/vue-material-design-icons/ClipboardPlusOutline.vue';
import ClockIcon from '@apostrophecms/vue-material-design-icons/Clock.vue';
import CloseCircleIcon from '@apostrophecms/vue-material-design-icons/CloseCircle.vue';
import CloseIcon from '@apostrophecms/vue-material-design-icons/Close.vue';
import CloudUploadIcon from '@apostrophecms/vue-material-design-icons/CloudUpload.vue';
import CodeTagsIcon from '@apostrophecms/vue-material-design-icons/CodeTags.vue';
import CogIcon from '@apostrophecms/vue-material-design-icons/Cog.vue';
import ContentCopyIcon from '@apostrophecms/vue-material-design-icons/ContentCopy.vue';
import ContentCutIcon from '@apostrophecms/vue-material-design-icons/ContentCut.vue';
import CursorDefaultClickIcon from '@apostrophecms/vue-material-design-icons/CursorDefaultClick.vue';
import DeleteIcon from '@apostrophecms/vue-material-design-icons/Delete.vue';
import DotsVerticalIcon from '@apostrophecms/vue-material-design-icons/DotsVertical.vue';
import AppsIcon from '@apostrophecms/vue-material-design-icons/Apps.vue';
import EyeIcon from '@apostrophecms/vue-material-design-icons/Eye.vue';
import FileDocumentIcon from '@apostrophecms/vue-material-design-icons/FileDocument.vue';
import FileTreeIcon from '@apostrophecms/vue-material-design-icons/FileTree.vue';
import FlareIcon from '@apostrophecms/vue-material-design-icons/Flare.vue';
import FormatAlignCenterIcon from '@apostrophecms/vue-material-design-icons/FormatAlignCenter.vue';
import FormatAlignJustifyIcon from '@apostrophecms/vue-material-design-icons/FormatAlignJustify.vue';
import FormatAlignLeftIcon from '@apostrophecms/vue-material-design-icons/FormatAlignLeft.vue';
import FormatAlignRightIcon from '@apostrophecms/vue-material-design-icons/FormatAlignRight.vue';
import FormatBoldIcon from '@apostrophecms/vue-material-design-icons/FormatBold.vue';
import FormatFontIcon from '@apostrophecms/vue-material-design-icons/FormatFont.vue';
import FormatItalicIcon from '@apostrophecms/vue-material-design-icons/FormatItalic.vue';
import FormatListBulletedIcon from '@apostrophecms/vue-material-design-icons/FormatListBulleted.vue';
import FormatListNumberedIcon from '@apostrophecms/vue-material-design-icons/FormatListNumbered.vue';
import FormatQuoteCloseIcon from '@apostrophecms/vue-material-design-icons/FormatQuoteClose.vue';
import FormatStrikethroughVariantIcon from '@apostrophecms/vue-material-design-icons/FormatStrikethroughVariant.vue';
import FormatSuperscriptIcon from '@apostrophecms/vue-material-design-icons/FormatSuperscript.vue';
import FormatSubscriptIcon from '@apostrophecms/vue-material-design-icons/FormatSubscript.vue';
import FormatTextIcon from '@apostrophecms/vue-material-design-icons/FormatText.vue';
import FormatUnderlineIcon from '@apostrophecms/vue-material-design-icons/FormatUnderline.vue';
import HelpCircleIcon from '@apostrophecms/vue-material-design-icons/HelpCircle.vue';
import ImageEditOutlineIcon from '@apostrophecms/vue-material-design-icons/ImageEditOutline.vue';
import ImageIcon from '@apostrophecms/vue-material-design-icons/Image.vue';
import ImageSizeSelectActualIcon from '@apostrophecms/vue-material-design-icons/ImageSizeSelectActual.vue';
import InformationIcon from '@apostrophecms/vue-material-design-icons/Information.vue';
import InformationOutlineIcon from '@apostrophecms/vue-material-design-icons/InformationOutline.vue';
import InstagramIcon from '@apostrophecms/vue-material-design-icons/Instagram.vue';
import KeyboardBackspaceIcon from '@apostrophecms/vue-material-design-icons/KeyboardBackspace.vue';
import KeyboardEscIcon from '@apostrophecms/vue-material-design-icons/KeyboardEsc.vue';
import KeyboardF1Icon from '@apostrophecms/vue-material-design-icons/KeyboardF1.vue';
import KeyboardF10Icon from '@apostrophecms/vue-material-design-icons/KeyboardF10.vue';
import KeyboardF11Icon from '@apostrophecms/vue-material-design-icons/KeyboardF11.vue';
import KeyboardF12Icon from '@apostrophecms/vue-material-design-icons/KeyboardF12.vue';
import KeyboardF2Icon from '@apostrophecms/vue-material-design-icons/KeyboardF2.vue';
import KeyboardF3Icon from '@apostrophecms/vue-material-design-icons/KeyboardF3.vue';
import KeyboardF4Icon from '@apostrophecms/vue-material-design-icons/KeyboardF4.vue';
import KeyboardF5Icon from '@apostrophecms/vue-material-design-icons/KeyboardF5.vue';
import KeyboardF6Icon from '@apostrophecms/vue-material-design-icons/KeyboardF6.vue';
import KeyboardF7Icon from '@apostrophecms/vue-material-design-icons/KeyboardF7.vue';
import KeyboardF8Icon from '@apostrophecms/vue-material-design-icons/KeyboardF8.vue';
import KeyboardF9Icon from '@apostrophecms/vue-material-design-icons/KeyboardF9.vue';
import KeyboardReturnIcon from '@apostrophecms/vue-material-design-icons/KeyboardReturn.vue';
import KeyboardSpaceIcon from '@apostrophecms/vue-material-design-icons/KeyboardSpace.vue';
import KeyboardTabIcon from '@apostrophecms/vue-material-design-icons/KeyboardTab.vue';
import LabelIcon from '@apostrophecms/vue-material-design-icons/Label.vue';
import LightbulbOnIcon from '@apostrophecms/vue-material-design-icons/LightbulbOn.vue';
import LinkIcon from '@apostrophecms/vue-material-design-icons/Link.vue';
import ListStatusIcon from '@apostrophecms/vue-material-design-icons/ListStatus.vue';
import LockIcon from '@apostrophecms/vue-material-design-icons/Lock.vue';
import MagnifyIcon from '@apostrophecms/vue-material-design-icons/Magnify.vue';
import MapMarkerIcon from '@apostrophecms/vue-material-design-icons/MapMarker.vue';
import MenuDownIcon from '@apostrophecms/vue-material-design-icons/MenuDown.vue';
import MinusBoxIcon from '@apostrophecms/vue-material-design-icons/MinusBox.vue';
import MinusIcon from '@apostrophecms/vue-material-design-icons/Minus.vue';
import PaperclipIcon from '@apostrophecms/vue-material-design-icons/Paperclip.vue';
import PencilIcon from '@apostrophecms/vue-material-design-icons/Pencil.vue';
import PhoneIcon from '@apostrophecms/vue-material-design-icons/Phone.vue';
import PlayBoxIcon from '@apostrophecms/vue-material-design-icons/PlayBox.vue';
import PlusIcon from '@apostrophecms/vue-material-design-icons/Plus.vue';
import RedoVariantIcon from '@apostrophecms/vue-material-design-icons/RedoVariant.vue';
import RefreshIcon from '@apostrophecms/vue-material-design-icons/Refresh.vue';
import ShapeIcon from '@apostrophecms/vue-material-design-icons/Shape.vue';
import SignTextIcon from '@apostrophecms/vue-material-design-icons/SignText.vue';
import TagIcon from '@apostrophecms/vue-material-design-icons/Tag.vue';
import TextBoxIcon from '@apostrophecms/vue-material-design-icons/TextBox.vue';
import TextBoxRemoveIcon from '@apostrophecms/vue-material-design-icons/TextBoxRemove.vue';
import TrashCanIcon from '@apostrophecms/vue-material-design-icons/TrashCan.vue';
import TrashCanOutlineIcon from '@apostrophecms/vue-material-design-icons/TrashCanOutline.vue';
import TuneIcon from '@apostrophecms/vue-material-design-icons/Tune.vue';
import UndoVariantIcon from '@apostrophecms/vue-material-design-icons/UndoVariant.vue';
import UnfoldLessHorizontalIcon from '@apostrophecms/vue-material-design-icons/UnfoldLessHorizontal.vue';
import UnfoldMoreHorizontalIcon from '@apostrophecms/vue-material-design-icons/UnfoldMoreHorizontal.vue';
import VideoIcon from '@apostrophecms/vue-material-design-icons/Video.vue';
import ViewColumnIcon from '@apostrophecms/vue-material-design-icons/ViewColumn.vue';
import WebIcon from '@apostrophecms/vue-material-design-icons/Web.vue';
import DatabaseExportIcon from '@apostrophecms/vue-material-design-icons/DatabaseExport.vue';
import FormatColorHighlightIcon from '@apostrophecms/vue-material-design-icons/FormatColorHighlight.vue';
import TableIcon from '@apostrophecms/vue-material-design-icons/Table.vue';
import PaletteSwatchIcon from '@apostrophecms/vue-material-design-icons/PaletteSwatch.vue';
import EarthIcon from '@apostrophecms/vue-material-design-icons/Earth.vue';
import DatabaseCheckIcon from '@apostrophecms/vue-material-design-icons/DatabaseCheck.vue';
import TrayFullIcon from '@apostrophecms/vue-material-design-icons/TrayFull.vue';

              
              import AposI18nLocalize from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/i18n/ui/apos/components/AposI18nLocalize.vue";
              

              import AposI18nLocalizeErrors from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/i18n/ui/apos/components/AposI18nLocalizeErrors.vue";
              

              import AposArrayEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposArrayEditor.vue";
              

              import AposInputArea from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputArea.vue";
              

              import AposInputArray from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputArray.vue";
              

              import AposInputAttachment from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputAttachment.vue";
              

              import AposInputBoolean from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputBoolean.vue";
              

              import AposInputCheckboxes from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputCheckboxes.vue";
              

              import AposInputColor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputColor.vue";
              

              import AposInputDateAndTime from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputDateAndTime.vue";
              

              import AposInputObject from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputObject.vue";
              

              import AposInputPassword from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputPassword.vue";
              

              import AposInputRadio from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputRadio.vue";
              

              import AposInputRange from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputRange.vue";
              

              import AposInputRelationship from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputRelationship.vue";
              

              import AposInputSelect from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputSelect.vue";
              

              import AposInputSlug from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputSlug.vue";
              

              import AposInputString from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputString.vue";
              

              import AposInputWrapper from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposInputWrapper.vue";
              

              import AposLogo from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposLogo.vue";
              

              import AposLogoIcon from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposLogoIcon.vue";
              

              import AposLogoPadless from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposLogoPadless.vue";
              

              import AposSchema from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposSchema.vue";
              

              import AposSearchList from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposSearchList.vue";
              

              import AposSubform from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/schema/ui/apos/components/AposSubform.vue";
              

              import TheAposBusy from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/busy/ui/apos/components/TheAposBusy.vue";
              

              import AposInputRole from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/permission/ui/apos/components/AposInputRole.vue";
              

              import AposPermissionGrid from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/permission/ui/apos/components/AposPermissionGrid.vue";
              

              import TheAposAdminBar from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposAdminBar.vue";
              

              import TheAposAdminBarLocale from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposAdminBarLocale.vue";
              

              import TheAposAdminBarMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposAdminBarMenu.vue";
              

              import TheAposAdminBarUser from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposAdminBarUser.vue";
              

              import TheAposContextBar from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposContextBar.vue";
              

              import TheAposContextModeAndSettings from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposContextModeAndSettings.vue";
              

              import TheAposContextTitle from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposContextTitle.vue";
              

              import TheAposContextUndoRedo from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposContextUndoRedo.vue";
              

              import TheAposSavingIndicator from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/components/TheAposSavingIndicator.vue";
              

              import AposNotification from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/notification/ui/apos/components/AposNotification.vue";
              

              import TheAposNotifications from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/notification/ui/apos/components/TheAposNotifications.vue";
              

              import AposForgotPasswordForm from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/components/AposForgotPasswordForm.vue";
              

              import AposLoginForm from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/components/AposLoginForm.vue";
              

              import AposResetPasswordForm from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/components/AposResetPasswordForm.vue";
              

              import TheAposLogin from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/components/TheAposLogin.vue";
              

              import TheAposLoginHeader from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/components/TheAposLoginHeader.vue";
              

              import AposDocsManagerToolbar from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposDocsManagerToolbar.vue";
              

              import AposModal from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModal.vue";
              

              import AposModalBody from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalBody.vue";
              

              import AposModalBreadcrumbs from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalBreadcrumbs.vue";
              

              import AposModalConfirm from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalConfirm.vue";
              

              import AposModalLip from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalLip.vue";
              

              import AposModalRail from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalRail.vue";
              

              import AposModalShareDraft from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalShareDraft.vue";
              

              import AposModalTabs from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalTabs.vue";
              

              import AposModalTabsBody from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalTabsBody.vue";
              

              import AposModalToolbar from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/AposModalToolbar.vue";
              

              import TheAposModals from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/components/TheAposModals.vue";
              

              import AposDocContextMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/doc-type/ui/apos/components/AposDocContextMenu.vue";
              

              import AposDocEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/doc-type/ui/apos/components/AposDocEditor.vue";
              

              import AposDocsManager from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/piece-type/ui/apos/components/AposDocsManager.vue";
              

              import AposDocsManagerDisplay from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/piece-type/ui/apos/components/AposDocsManagerDisplay.vue";
              

              import AposDocsManagerSelectBox from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/piece-type/ui/apos/components/AposDocsManagerSelectBox.vue";
              

              import AposRelationshipEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/piece-type/ui/apos/components/AposRelationshipEditor.vue";
              

              import AposUtilityOperations from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/piece-type/ui/apos/components/AposUtilityOperations.vue";
              

              import AposPagesManager from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/page/ui/apos/components/AposPagesManager.vue";
              

              import AposAreaContextualMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaContextualMenu.vue";
              

              import AposAreaEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaEditor.vue";
              

              import AposAreaExpandedMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaExpandedMenu.vue";
              

              import AposAreaMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaMenu.vue";
              

              import AposAreaMenuItem from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaMenuItem.vue";
              

              import AposAreaWidget from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposAreaWidget.vue";
              

              import AposWidgetControls from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/components/AposWidgetControls.vue";
              

              import AposWidget from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/widget-type/ui/apos/components/AposWidget.vue";
              

              import AposWidgetEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/widget-type/ui/apos/components/AposWidgetEditor.vue";
              

              import AposImageControlDialog from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposImageControlDialog.vue";
              

              import AposRichTextWidgetEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposRichTextWidgetEditor.vue";
              

              import AposTiptapAnchor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapAnchor.vue";
              

              import AposTiptapButton from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapButton.vue";
              

              import AposTiptapDivider from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapDivider.vue";
              

              import AposTiptapImage from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapImage.vue";
              

              import AposTiptapLink from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapLink.vue";
              

              import AposTiptapMarks from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapMarks.vue";
              

              import AposTiptapStyles from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapStyles.vue";
              

              import AposTiptapTable from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapTable.vue";
              

              import AposTiptapUndefined from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/components/AposTiptapUndefined.vue";
              

              import AposInputOembed from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/oembed-field/ui/apos/components/AposInputOembed.vue";
              

              import AposAvatar from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposAvatar.vue";
              

              import AposButton from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposButton.vue";
              

              import AposButtonGroup from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposButtonGroup.vue";
              

              import AposButtonSplit from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposButtonSplit.vue";
              

              import AposCellBasic from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellBasic.vue";
              

              import AposCellButton from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellButton.vue";
              

              import AposCellContextMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellContextMenu.vue";
              

              import AposCellDate from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellDate.vue";
              

              import AposCellLabels from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellLabels.vue";
              

              import AposCellLastEdited from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellLastEdited.vue";
              

              import AposCellLink from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellLink.vue";
              

              import AposCellType from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCellType.vue";
              

              import AposCheckbox from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCheckbox.vue";
              

              import AposCloudUploadIcon from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCloudUploadIcon.vue";
              

              import AposCombo from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposCombo.vue";
              

              import AposContextMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposContextMenu.vue";
              

              import AposContextMenuDialog from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposContextMenuDialog.vue";
              

              import AposContextMenuItem from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposContextMenuItem.vue";
              

              import AposContextMenuTip from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposContextMenuTip.vue";
              

              import AposEmptyState from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposEmptyState.vue";
              

              import AposFile from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposFile.vue";
              

              import AposFilterMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposFilterMenu.vue";
              

              import AposIndicator from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposIndicator.vue";
              

              import AposLabel from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposLabel.vue";
              

              import AposLoading from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposLoading.vue";
              

              import AposMinMaxCount from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposMinMaxCount.vue";
              

              import AposPager from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposPager.vue";
              

              import AposPagerDots from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposPagerDots.vue";
              

              import AposSelect from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposSelect.vue";
              

              import AposSlat from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposSlat.vue";
              

              import AposSlatList from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposSlatList.vue";
              

              import AposSpinner from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposSpinner.vue";
              

              import AposSubformPreview from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposSubformPreview.vue";
              

              import AposTag from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTag.vue";
              

              import AposTagApply from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTagApply.vue";
              

              import AposTagList from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTagList.vue";
              

              import AposTagListItem from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTagListItem.vue";
              

              import AposToggle from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposToggle.vue";
              

              import AposTree from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTree.vue";
              

              import AposTreeHeader from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTreeHeader.vue";
              

              import AposTreeRows from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/components/AposTreeRows.vue";
              

              import AposSettingsManager from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/settings/ui/apos/components/AposSettingsManager.vue";
              

              import AposImageCropper from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposImageCropper.vue";
              

              import AposImageRelationshipEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposImageRelationshipEditor.vue";
              

              import AposMediaManager from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposMediaManager.vue";
              

              import AposMediaManagerDisplay from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposMediaManagerDisplay.vue";
              

              import AposMediaManagerEditor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposMediaManagerEditor.vue";
              

              import AposMediaManagerSelections from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposMediaManagerSelections.vue";
              

              import AposMediaUploader from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/components/AposMediaUploader.vue";
              

              import AposSubmittedDraftIcon from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/submitted-draft/ui/apos/components/AposSubmittedDraftIcon.vue";
              

              import AposCommandMenuKey from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/command-menu/ui/apos/components/AposCommandMenuKey.vue";
              

              import AposCommandMenuKeyList from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/command-menu/ui/apos/components/AposCommandMenuKeyList.vue";
              

              import AposCommandMenuShortcut from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/command-menu/ui/apos/components/AposCommandMenuShortcut.vue";
              

              import TheAposCommandMenu from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/command-menu/ui/apos/components/TheAposCommandMenu.vue";
              

              import AposTranslationIndicator from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/translation/ui/apos/components/AposTranslationIndicator.vue";
              

              
              import Anchor from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Anchor.js";
              

              import Classes from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Classes.js";
              

              import Default from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Default.js";
              

              import Div from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Div.js";
              

              import Document from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Document.js";
              

              import Heading from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Heading.js";
              

              import Image from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Image.js";
              

              import Link from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/Link.js";
              

              import ListItem from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/ListItem.js";
              

              import TextStyle from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/tiptap-extensions/TextStyle.js";
              

              
              import AposI18nCrossDomainSession_0App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/i18n/ui/apos/apps/AposI18nCrossDomainSession.js";
              

              import AposBusy_1App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/busy/ui/apos/apps/AposBusy.js";
              

              import AposAdminBar_2App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/apos/apps/AposAdminBar.js";
              

              import AposNotification_3App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/notification/ui/apos/apps/AposNotification.js";
              

              import AposLogin_4App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/login/ui/apos/apps/AposLogin.js";
              

              import AposDoc_5App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/doc/ui/apos/apps/AposDoc.js";
              

              import AposModals_6App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/modal/ui/apos/apps/AposModals.js";
              

              import AposAreas_7App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/area/ui/apos/apps/AposAreas.js";
              

              import AposRichTextPermalinkResolver_8App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/rich-text-widget/ui/apos/apps/AposRichTextPermalinkResolver.js";
              

              import AposBusEvent_9App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/ui/ui/apos/apps/AposBusEvent.js";
              

              import TheAposSettings_10App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/settings/ui/apos/apps/TheAposSettings.js";
              

              import AposImageRelationshipQueryFilter_11App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/image/ui/apos/apps/AposImageRelationshipQueryFilter.js";
              

              import AposCommandMenu_12App from "/home/ubuntu/production/source/site/node_modules/apostrophe/modules/@apostrophecms/command-menu/ui/apos/apps/AposCommandMenu.js";
              

              
              
              window.apos.iconComponents = window.apos.iconComponents || {};
window.apos.iconComponents['account-box-icon'] = AccountBoxIcon;
window.apos.iconComponents['alert-circle-icon'] = AlertCircleIcon;
window.apos.iconComponents['alpha-x-icon'] = AlphaXIcon;
window.apos.iconComponents['anchor-icon'] = AnchorIcon;
window.apos.iconComponents['apple-keyboard-caps'] = AppleKeyboardCapsIcon;
window.apos.iconComponents['apple-keyboard-command'] = AppleKeyboardCommandIcon;
window.apos.iconComponents['apple-keyboard-control'] = AppleKeyboardControlIcon;
window.apos.iconComponents['apple-keyboard-option'] = AppleKeyboardOptionIcon;
window.apos.iconComponents['apple-keyboard-shift'] = AppleKeyboardShiftIcon;
window.apos.iconComponents['archive-arrow-down-icon'] = ArchiveArrowDownIcon;
window.apos.iconComponents['archive-arrow-up-icon'] = ArchiveArrowUpIcon;
window.apos.iconComponents['arrow-down-icon'] = ArrowDownIcon;
window.apos.iconComponents['arrow-left-icon'] = ArrowLeftIcon;
window.apos.iconComponents['arrow-right-icon'] = ArrowRightIcon;
window.apos.iconComponents['arrow-up-icon'] = ArrowUpIcon;
window.apos.iconComponents['binoculars-icon'] = BinocularsIcon;
window.apos.iconComponents['calendar-icon'] = CalendarIcon;
window.apos.iconComponents['check-all-icon'] = CheckAllIcon;
window.apos.iconComponents['check-bold-icon'] = CheckBoldIcon;
window.apos.iconComponents['check-circle-icon'] = CheckCircleIcon;
window.apos.iconComponents['check-decagram-icon'] = CheckDecagramIcon;
window.apos.iconComponents['checkbox-blank-icon'] = CheckboxBlankOutlineIcon;
window.apos.iconComponents['checkbox-marked-icon'] = CheckboxMarkedIcon;
window.apos.iconComponents['chevron-down-icon'] = ChevronDownIcon;
window.apos.iconComponents['chevron-left-icon'] = ChevronLeftIcon;
window.apos.iconComponents['chevron-right-icon'] = ChevronRightIcon;
window.apos.iconComponents['chevron-up-icon'] = ChevronUpIcon;
window.apos.iconComponents['circle-icon'] = CircleIcon;
window.apos.iconComponents['circle-medium-icon'] = CircleMediumIcon;
window.apos.iconComponents['clipboard-plus-icon'] = ClipboardPlusIcon;
window.apos.iconComponents['clipboard-plus-outline-icon'] = ClipboardPlusOutlineIcon;
window.apos.iconComponents['clock-icon'] = ClockIcon;
window.apos.iconComponents['close-circle-icon'] = CloseCircleIcon;
window.apos.iconComponents['close-icon'] = CloseIcon;
window.apos.iconComponents['cloud-upload-icon'] = CloudUploadIcon;
window.apos.iconComponents['code-tags-icon'] = CodeTagsIcon;
window.apos.iconComponents['cog-icon'] = CogIcon;
window.apos.iconComponents['content-copy-icon'] = ContentCopyIcon;
window.apos.iconComponents['content-cut-icon'] = ContentCutIcon;
window.apos.iconComponents['cursor-default-click-icon'] = CursorDefaultClickIcon;
window.apos.iconComponents['delete-icon'] = DeleteIcon;
window.apos.iconComponents['dots-vertical-icon'] = DotsVerticalIcon;
window.apos.iconComponents['drag-icon'] = AppsIcon;
window.apos.iconComponents['eye-icon'] = EyeIcon;
window.apos.iconComponents['file-document-icon'] = FileDocumentIcon;
window.apos.iconComponents['file-tree-icon'] = FileTreeIcon;
window.apos.iconComponents['flare-icon'] = FlareIcon;
window.apos.iconComponents['format-align-center-icon'] = FormatAlignCenterIcon;
window.apos.iconComponents['format-align-justify-icon'] = FormatAlignJustifyIcon;
window.apos.iconComponents['format-align-left-icon'] = FormatAlignLeftIcon;
window.apos.iconComponents['format-align-right-icon'] = FormatAlignRightIcon;
window.apos.iconComponents['format-bold-icon'] = FormatBoldIcon;
window.apos.iconComponents['format-font-icon'] = FormatFontIcon;
window.apos.iconComponents['format-italic-icon'] = FormatItalicIcon;
window.apos.iconComponents['format-list-bulleted-icon'] = FormatListBulletedIcon;
window.apos.iconComponents['format-list-numbered-icon'] = FormatListNumberedIcon;
window.apos.iconComponents['format-quote-close-icon'] = FormatQuoteCloseIcon;
window.apos.iconComponents['format-strikethrough-variant-icon'] = FormatStrikethroughVariantIcon;
window.apos.iconComponents['format-superscript-icon'] = FormatSuperscriptIcon;
window.apos.iconComponents['format-subscript-icon'] = FormatSubscriptIcon;
window.apos.iconComponents['format-text-icon'] = FormatTextIcon;
window.apos.iconComponents['format-underline-icon'] = FormatUnderlineIcon;
window.apos.iconComponents['help-circle-icon'] = HelpCircleIcon;
window.apos.iconComponents['image-edit-outline'] = ImageEditOutlineIcon;
window.apos.iconComponents['image-icon'] = ImageIcon;
window.apos.iconComponents['image-size-select-actual-icon'] = ImageSizeSelectActualIcon;
window.apos.iconComponents['information-icon'] = InformationIcon;
window.apos.iconComponents['information-outline-icon'] = InformationOutlineIcon;
window.apos.iconComponents['instagram-icon'] = InstagramIcon;
window.apos.iconComponents['keyboard-backspace'] = KeyboardBackspaceIcon;
window.apos.iconComponents['keyboard-esc'] = KeyboardEscIcon;
window.apos.iconComponents['keyboard-f1'] = KeyboardF1Icon;
window.apos.iconComponents['keyboard-f10'] = KeyboardF10Icon;
window.apos.iconComponents['keyboard-f11'] = KeyboardF11Icon;
window.apos.iconComponents['keyboard-f12'] = KeyboardF12Icon;
window.apos.iconComponents['keyboard-f2'] = KeyboardF2Icon;
window.apos.iconComponents['keyboard-f3'] = KeyboardF3Icon;
window.apos.iconComponents['keyboard-f4'] = KeyboardF4Icon;
window.apos.iconComponents['keyboard-f5'] = KeyboardF5Icon;
window.apos.iconComponents['keyboard-f6'] = KeyboardF6Icon;
window.apos.iconComponents['keyboard-f7'] = KeyboardF7Icon;
window.apos.iconComponents['keyboard-f8'] = KeyboardF8Icon;
window.apos.iconComponents['keyboard-f9'] = KeyboardF9Icon;
window.apos.iconComponents['keyboard-return'] = KeyboardReturnIcon;
window.apos.iconComponents['keyboard-space'] = KeyboardSpaceIcon;
window.apos.iconComponents['keyboard-tab'] = KeyboardTabIcon;
window.apos.iconComponents['label-icon'] = LabelIcon;
window.apos.iconComponents['lightbulb-on-icon'] = LightbulbOnIcon;
window.apos.iconComponents['link-icon'] = LinkIcon;
window.apos.iconComponents['list-status-icon'] = ListStatusIcon;
window.apos.iconComponents['lock-icon'] = LockIcon;
window.apos.iconComponents['magnify-icon'] = MagnifyIcon;
window.apos.iconComponents['map-marker-icon'] = MapMarkerIcon;
window.apos.iconComponents['menu-down-icon'] = MenuDownIcon;
window.apos.iconComponents['minus-box-icon'] = MinusBoxIcon;
window.apos.iconComponents['minus-icon'] = MinusIcon;
window.apos.iconComponents['paperclip-icon'] = PaperclipIcon;
window.apos.iconComponents['pencil-icon'] = PencilIcon;
window.apos.iconComponents['phone-icon'] = PhoneIcon;
window.apos.iconComponents['play-box-icon'] = PlayBoxIcon;
window.apos.iconComponents['plus-icon'] = PlusIcon;
window.apos.iconComponents['redo-icon'] = RedoVariantIcon;
window.apos.iconComponents['refresh-icon'] = RefreshIcon;
window.apos.iconComponents['shape-icon'] = ShapeIcon;
window.apos.iconComponents['sign-text-icon'] = SignTextIcon;
window.apos.iconComponents['tag-icon'] = TagIcon;
window.apos.iconComponents['text-box-icon'] = TextBoxIcon;
window.apos.iconComponents['text-box-remove-icon'] = TextBoxRemoveIcon;
window.apos.iconComponents['trash-can-icon'] = TrashCanIcon;
window.apos.iconComponents['trash-can-outline-icon'] = TrashCanOutlineIcon;
window.apos.iconComponents['tune-icon'] = TuneIcon;
window.apos.iconComponents['undo-icon'] = UndoVariantIcon;
window.apos.iconComponents['unfold-less-horizontal-icon'] = UnfoldLessHorizontalIcon;
window.apos.iconComponents['unfold-more-horizontal-icon'] = UnfoldMoreHorizontalIcon;
window.apos.iconComponents['video-icon'] = VideoIcon;
window.apos.iconComponents['view-column-icon'] = ViewColumnIcon;
window.apos.iconComponents['web-icon'] = WebIcon;
window.apos.iconComponents['database-export-icon'] = DatabaseExportIcon;
window.apos.iconComponents['format-color-highlight-icon'] = FormatColorHighlightIcon;
window.apos.iconComponents['table-icon'] = TableIcon;
window.apos.iconComponents['palette-swatch-icon'] = PaletteSwatchIcon;
window.apos.iconComponents['earth-icon'] = EarthIcon;
window.apos.iconComponents['database-check-icon'] = DatabaseCheckIcon;
window.apos.iconComponents['tray-full-icon'] = TrayFullIcon;

              window.apos.vueComponents = window.apos.vueComponents || {};
window.apos.vueComponents["AposI18nLocalize"] = AposI18nLocalize;
window.apos.vueComponents["AposI18nLocalizeErrors"] = AposI18nLocalizeErrors;
window.apos.vueComponents["AposArrayEditor"] = AposArrayEditor;
window.apos.vueComponents["AposInputArea"] = AposInputArea;
window.apos.vueComponents["AposInputArray"] = AposInputArray;
window.apos.vueComponents["AposInputAttachment"] = AposInputAttachment;
window.apos.vueComponents["AposInputBoolean"] = AposInputBoolean;
window.apos.vueComponents["AposInputCheckboxes"] = AposInputCheckboxes;
window.apos.vueComponents["AposInputColor"] = AposInputColor;
window.apos.vueComponents["AposInputDateAndTime"] = AposInputDateAndTime;
window.apos.vueComponents["AposInputObject"] = AposInputObject;
window.apos.vueComponents["AposInputPassword"] = AposInputPassword;
window.apos.vueComponents["AposInputRadio"] = AposInputRadio;
window.apos.vueComponents["AposInputRange"] = AposInputRange;
window.apos.vueComponents["AposInputRelationship"] = AposInputRelationship;
window.apos.vueComponents["AposInputSelect"] = AposInputSelect;
window.apos.vueComponents["AposInputSlug"] = AposInputSlug;
window.apos.vueComponents["AposInputString"] = AposInputString;
window.apos.vueComponents["AposInputWrapper"] = AposInputWrapper;
window.apos.vueComponents["AposLogo"] = AposLogo;
window.apos.vueComponents["AposLogoIcon"] = AposLogoIcon;
window.apos.vueComponents["AposLogoPadless"] = AposLogoPadless;
window.apos.vueComponents["AposSchema"] = AposSchema;
window.apos.vueComponents["AposSearchList"] = AposSearchList;
window.apos.vueComponents["AposSubform"] = AposSubform;
window.apos.vueComponents["TheAposBusy"] = TheAposBusy;
window.apos.vueComponents["AposInputRole"] = AposInputRole;
window.apos.vueComponents["AposPermissionGrid"] = AposPermissionGrid;
window.apos.vueComponents["TheAposAdminBar"] = TheAposAdminBar;
window.apos.vueComponents["TheAposAdminBarLocale"] = TheAposAdminBarLocale;
window.apos.vueComponents["TheAposAdminBarMenu"] = TheAposAdminBarMenu;
window.apos.vueComponents["TheAposAdminBarUser"] = TheAposAdminBarUser;
window.apos.vueComponents["TheAposContextBar"] = TheAposContextBar;
window.apos.vueComponents["TheAposContextModeAndSettings"] = TheAposContextModeAndSettings;
window.apos.vueComponents["TheAposContextTitle"] = TheAposContextTitle;
window.apos.vueComponents["TheAposContextUndoRedo"] = TheAposContextUndoRedo;
window.apos.vueComponents["TheAposSavingIndicator"] = TheAposSavingIndicator;
window.apos.vueComponents["AposNotification"] = AposNotification;
window.apos.vueComponents["TheAposNotifications"] = TheAposNotifications;
window.apos.vueComponents["AposForgotPasswordForm"] = AposForgotPasswordForm;
window.apos.vueComponents["AposLoginForm"] = AposLoginForm;
window.apos.vueComponents["AposResetPasswordForm"] = AposResetPasswordForm;
window.apos.vueComponents["TheAposLogin"] = TheAposLogin;
window.apos.vueComponents["TheAposLoginHeader"] = TheAposLoginHeader;
window.apos.vueComponents["AposDocsManagerToolbar"] = AposDocsManagerToolbar;
window.apos.vueComponents["AposModal"] = AposModal;
window.apos.vueComponents["AposModalBody"] = AposModalBody;
window.apos.vueComponents["AposModalBreadcrumbs"] = AposModalBreadcrumbs;
window.apos.vueComponents["AposModalConfirm"] = AposModalConfirm;
window.apos.vueComponents["AposModalLip"] = AposModalLip;
window.apos.vueComponents["AposModalRail"] = AposModalRail;
window.apos.vueComponents["AposModalShareDraft"] = AposModalShareDraft;
window.apos.vueComponents["AposModalTabs"] = AposModalTabs;
window.apos.vueComponents["AposModalTabsBody"] = AposModalTabsBody;
window.apos.vueComponents["AposModalToolbar"] = AposModalToolbar;
window.apos.vueComponents["TheAposModals"] = TheAposModals;
window.apos.vueComponents["AposDocContextMenu"] = AposDocContextMenu;
window.apos.vueComponents["AposDocEditor"] = AposDocEditor;
window.apos.vueComponents["AposDocsManager"] = AposDocsManager;
window.apos.vueComponents["AposDocsManagerDisplay"] = AposDocsManagerDisplay;
window.apos.vueComponents["AposDocsManagerSelectBox"] = AposDocsManagerSelectBox;
window.apos.vueComponents["AposRelationshipEditor"] = AposRelationshipEditor;
window.apos.vueComponents["AposUtilityOperations"] = AposUtilityOperations;
window.apos.vueComponents["AposPagesManager"] = AposPagesManager;
window.apos.vueComponents["AposAreaContextualMenu"] = AposAreaContextualMenu;
window.apos.vueComponents["AposAreaEditor"] = AposAreaEditor;
window.apos.vueComponents["AposAreaExpandedMenu"] = AposAreaExpandedMenu;
window.apos.vueComponents["AposAreaMenu"] = AposAreaMenu;
window.apos.vueComponents["AposAreaMenuItem"] = AposAreaMenuItem;
window.apos.vueComponents["AposAreaWidget"] = AposAreaWidget;
window.apos.vueComponents["AposWidgetControls"] = AposWidgetControls;
window.apos.vueComponents["AposWidget"] = AposWidget;
window.apos.vueComponents["AposWidgetEditor"] = AposWidgetEditor;
window.apos.vueComponents["AposImageControlDialog"] = AposImageControlDialog;
window.apos.vueComponents["AposRichTextWidgetEditor"] = AposRichTextWidgetEditor;
window.apos.vueComponents["AposTiptapAnchor"] = AposTiptapAnchor;
window.apos.vueComponents["AposTiptapButton"] = AposTiptapButton;
window.apos.vueComponents["AposTiptapDivider"] = AposTiptapDivider;
window.apos.vueComponents["AposTiptapImage"] = AposTiptapImage;
window.apos.vueComponents["AposTiptapLink"] = AposTiptapLink;
window.apos.vueComponents["AposTiptapMarks"] = AposTiptapMarks;
window.apos.vueComponents["AposTiptapStyles"] = AposTiptapStyles;
window.apos.vueComponents["AposTiptapTable"] = AposTiptapTable;
window.apos.vueComponents["AposTiptapUndefined"] = AposTiptapUndefined;
window.apos.vueComponents["AposInputOembed"] = AposInputOembed;
window.apos.vueComponents["AposAvatar"] = AposAvatar;
window.apos.vueComponents["AposButton"] = AposButton;
window.apos.vueComponents["AposButtonGroup"] = AposButtonGroup;
window.apos.vueComponents["AposButtonSplit"] = AposButtonSplit;
window.apos.vueComponents["AposCellBasic"] = AposCellBasic;
window.apos.vueComponents["AposCellButton"] = AposCellButton;
window.apos.vueComponents["AposCellContextMenu"] = AposCellContextMenu;
window.apos.vueComponents["AposCellDate"] = AposCellDate;
window.apos.vueComponents["AposCellLabels"] = AposCellLabels;
window.apos.vueComponents["AposCellLastEdited"] = AposCellLastEdited;
window.apos.vueComponents["AposCellLink"] = AposCellLink;
window.apos.vueComponents["AposCellType"] = AposCellType;
window.apos.vueComponents["AposCheckbox"] = AposCheckbox;
window.apos.vueComponents["AposCloudUploadIcon"] = AposCloudUploadIcon;
window.apos.vueComponents["AposCombo"] = AposCombo;
window.apos.vueComponents["AposContextMenu"] = AposContextMenu;
window.apos.vueComponents["AposContextMenuDialog"] = AposContextMenuDialog;
window.apos.vueComponents["AposContextMenuItem"] = AposContextMenuItem;
window.apos.vueComponents["AposContextMenuTip"] = AposContextMenuTip;
window.apos.vueComponents["AposEmptyState"] = AposEmptyState;
window.apos.vueComponents["AposFile"] = AposFile;
window.apos.vueComponents["AposFilterMenu"] = AposFilterMenu;
window.apos.vueComponents["AposIndicator"] = AposIndicator;
window.apos.vueComponents["AposLabel"] = AposLabel;
window.apos.vueComponents["AposLoading"] = AposLoading;
window.apos.vueComponents["AposMinMaxCount"] = AposMinMaxCount;
window.apos.vueComponents["AposPager"] = AposPager;
window.apos.vueComponents["AposPagerDots"] = AposPagerDots;
window.apos.vueComponents["AposSelect"] = AposSelect;
window.apos.vueComponents["AposSlat"] = AposSlat;
window.apos.vueComponents["AposSlatList"] = AposSlatList;
window.apos.vueComponents["AposSpinner"] = AposSpinner;
window.apos.vueComponents["AposSubformPreview"] = AposSubformPreview;
window.apos.vueComponents["AposTag"] = AposTag;
window.apos.vueComponents["AposTagApply"] = AposTagApply;
window.apos.vueComponents["AposTagList"] = AposTagList;
window.apos.vueComponents["AposTagListItem"] = AposTagListItem;
window.apos.vueComponents["AposToggle"] = AposToggle;
window.apos.vueComponents["AposTree"] = AposTree;
window.apos.vueComponents["AposTreeHeader"] = AposTreeHeader;
window.apos.vueComponents["AposTreeRows"] = AposTreeRows;
window.apos.vueComponents["AposSettingsManager"] = AposSettingsManager;
window.apos.vueComponents["AposImageCropper"] = AposImageCropper;
window.apos.vueComponents["AposImageRelationshipEditor"] = AposImageRelationshipEditor;
window.apos.vueComponents["AposMediaManager"] = AposMediaManager;
window.apos.vueComponents["AposMediaManagerDisplay"] = AposMediaManagerDisplay;
window.apos.vueComponents["AposMediaManagerEditor"] = AposMediaManagerEditor;
window.apos.vueComponents["AposMediaManagerSelections"] = AposMediaManagerSelections;
window.apos.vueComponents["AposMediaUploader"] = AposMediaUploader;
window.apos.vueComponents["AposSubmittedDraftIcon"] = AposSubmittedDraftIcon;
window.apos.vueComponents["AposCommandMenuKey"] = AposCommandMenuKey;
window.apos.vueComponents["AposCommandMenuKeyList"] = AposCommandMenuKeyList;
window.apos.vueComponents["AposCommandMenuShortcut"] = AposCommandMenuShortcut;
window.apos.vueComponents["TheAposCommandMenu"] = TheAposCommandMenu;
window.apos.vueComponents["AposTranslationIndicator"] = AposTranslationIndicator;

              window.apos.tiptapExtensions = window.apos.tiptapExtensions || [];
apos.tiptapExtensions.push(Anchor);apos.tiptapExtensions.push(Classes);apos.tiptapExtensions.push(Default);apos.tiptapExtensions.push(Div);apos.tiptapExtensions.push(Document);apos.tiptapExtensions.push(Heading);apos.tiptapExtensions.push(Image);apos.tiptapExtensions.push(Link);apos.tiptapExtensions.push(ListItem);apos.tiptapExtensions.push(TextStyle);if (document.readyState !== 'loading') {
                setTimeout(invoke, 0);
              } else {
                window.addEventListener('DOMContentLoaded', invoke);
              }
              function invoke() {
                AposI18nCrossDomainSession_0App();
AposBusy_1App();
AposAdminBar_2App();
AposNotification_3App();
AposLogin_4App();
AposDoc_5App();
AposModals_6App();
AposAreas_7App();
AposRichTextPermalinkResolver_8App();
AposBusEvent_9App();
TheAposSettings_10App();
AposImageRelationshipQueryFilter_11App();
AposCommandMenu_12App();

              }